import { extendTheme, ChakraTheme } from '@chakra-ui/react';
import { StyleFunctionProps } from '@chakra-ui/theme-tools';
import { StepsStyleConfig } from 'chakra-ui-steps';

const CustomSteps = {
  ...StepsStyleConfig,
  baseStyle: (props: StyleFunctionProps) => {
    return {
      ...StepsStyleConfig.baseStyle(props),
      icon: {
        ...StepsStyleConfig.baseStyle(props).icon,
        strokeWidth: '0',
        background: '#FFF300',
      },
      stepIconContainer: {
        ...StepsStyleConfig.baseStyle(props).stepIconContainer,
        color: '#1565C0',
        border: '0',
        background: '#FFF300 !important',
        minWidth: '40px !important',
      },
      connector: {
        ...StepsStyleConfig.baseStyle(props).connector,
        borderColor: 'inherit',
      },
      label: {
        ...StepsStyleConfig.baseStyle(props).label,
        color: '#575757',
        textAlign: 'left',
      },
      labelContainer: {
        ...StepsStyleConfig.baseStyle(props).labelContainer,
        textAlign: 'left',
      },
      step: {
        ...StepsStyleConfig.baseStyle(props).step,
        textAlign: 'left !important',
      },
      description: {
        ...StepsStyleConfig.baseStyle(props).description,
        color: '#7E7E7E',
      },
    };
  },
};

const customTheme: Partial<ChakraTheme> = {
  fonts: {
    heading: 'Roboto, sans-serif',
    body: 'Roboto, sans-serif',
  },
  components: {
    Steps: CustomSteps,
  },
};

export const theme = extendTheme(customTheme);
export type CustomChakraTheme = typeof customTheme;
