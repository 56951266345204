import { CookiesProvider } from 'react-cookie';
import ReactDOM from 'react-dom';
import { RecoilRoot } from 'recoil';
import { App } from './App';

ReactDOM.render(
  <RecoilRoot>
    <CookiesProvider>
      <App />
    </CookiesProvider>
  </RecoilRoot>,
  document.getElementById('root')
);
