import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const ScrollToTop: React.FC = ({ children }) => {
  const location = useLocation();
  useEffect(() => {
    if (
      !(
        location.pathname === '/user/payment-methods/card' ||
        location.pathname === '/'
      )
    )
      window.scrollTo(0, 0);
  }, [location]);

  return <>{children}</>;
};

export default ScrollToTop;
