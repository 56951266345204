import { Container, VStack, Text } from '@chakra-ui/layout';
import { Spinner } from '@chakra-ui/spinner';
import { SystemProps } from '@chakra-ui/styled-system';

export interface LoaderProps {
  full?: boolean;
  message?: string;
  textTransform?: SystemProps['textTransform'];
}

const Loader: React.FC<LoaderProps> = ({
  message,
  textTransform,
  full = false,
}) => {
  if (full) {
    return (
      <Container
        id='loader-full-container'
        minW='100vw'
        minH='100vh'
        display='flex'
        justifyContent='center'
        alignItems='center'
      >
        <VStack spacing={5}>
          {message && (
            <Text
              id='loader-message'
              color='#1E50A0'
              fontWeight='bold'
              letterSpacing='1px'
              fontSize='3xl'
              textTransform={textTransform}
            >
              {message}
            </Text>
          )}
          <Spinner
            color='#1E50A0'
            emptyColor='gray.300'
            size='xl'
            thickness='3.5px'
          />
        </VStack>
      </Container>
    );
  }

  return (
    <VStack spacing={0.5}>
      <Spinner color='#1E50A0' emptyColor='#1E50A0' />
      {message && (
        <Text id='loader-message' color='#1E50A0' textTransform={textTransform}>
          {message}
        </Text>
      )}
    </VStack>
  );
};

export default Loader;
