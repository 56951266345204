import { useCookies } from 'react-cookie';
import { Navigate } from 'react-router-dom';

interface AuthProps {
  redirectPath?: string;
}

const AuthRoute: React.FC<AuthProps> = ({
  redirectPath = '/user/home',
  children,
}) => {
  const [cookies] = useCookies();
  const { auth } = cookies;

  if (!!auth) return <Navigate to={redirectPath} replace />;

  return <>{children}</>;
};

export default AuthRoute;
