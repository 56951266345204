import { useEffect } from 'react';
import { useCookies } from 'react-cookie';
import { useLocation } from 'react-router-dom';

const NotFoundRoute: React.FC = ({ children }) => {
  const [, , removeCookie] = useCookies();
  const { pathname } = useLocation();

  useEffect(() => {
    removeCookie('auth', { path: pathname });
  }, [removeCookie, pathname]);

  return <>{children}</>;
};

export default NotFoundRoute;
